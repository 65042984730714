var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WidgetFrame", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.$t("permission.active_tasks")) + " ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("ActiveTaskList", {
              staticClass: "task-list",
              on: { editTask: _vm.editTask },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }