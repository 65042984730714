<template>
  <WidgetFrame>
    <template v-slot:title>
      {{ $t('permission.active_tasks') }}
    </template>
    <template v-slot:content>
      <ActiveTaskList class="task-list" @editTask='editTask' />
    </template>
  </WidgetFrame>
</template>
<script>
  export default {
    name: 'HomeActiveTasksWidget',
    components: {
      ActiveTaskList: () => import('@/components/Dashboard/Widget/List/ActiveTaskList.vue'),
      WidgetFrame: () => import('@/components/Dashboard/WidgetFrame'),
    },
    watch: {
    },
    data() {
      return {
      };
    },
    created() {
    },
    methods: {
      editTask(params) {
        this.$emit('editTask', params);
      }
    }
  }
</script>

<style scoped>
  .task-list {
    flex-grow: 1;
  }
</style>