var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("WidgetFrame", {
    attrs: { contentClass: "overflow-unset" },
    on: {
      onResize: _vm.onResize,
      onOrientationChange: _vm.onOrientationChange,
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm._v(" " + _vm._s(_vm.$t("departments.title_selector")) + " "),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            !_vm.canView("PROJECT", ["TASK"])
              ? _c("div", { staticClass: "center-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "entity_selector.error.insufficient_permission_to_show_data"
                        )
                      ) +
                      " "
                  ),
                ])
              : !_vm.loaded
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              : _vm.noTasks
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("No tasks in project."),
                  ]),
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass:
                        "input-group project-departments-input-group",
                    },
                    [
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c(
                          "label",
                          {
                            staticClass: "input-group-text",
                            attrs: { for: "skillsType" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("dashboard.widgets.view_by")) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("multiselect", {
                        staticClass:
                          "custom-dropdown-options enable-option-icon",
                        attrs: {
                          "max-height": 300,
                          options: _vm.optionTypes.map((i) => i.key),
                          "custom-label": _vm.getTypeOptionLabel,
                          placeholder: "",
                          searchable: false,
                          "allow-empty": false,
                          showLabels: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (props) {
                              return [
                                _vm.type == props.option
                                  ? _c("font-awesome-icon", {
                                      staticClass: "selected-option-icon",
                                      attrs: { icon: ["far", "check"] },
                                    })
                                  : _vm._e(),
                                _c("span", { staticClass: "option__title" }, [
                                  _vm._v(
                                    _vm._s(_vm.getTypeOptionLabel(props.option))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "chart-holder" },
                    [
                      _vm.loaded
                        ? _c("ag-charts-vue", {
                            attrs: { options: _vm.options },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }